$grid-size : 50px;
$dual-ring-size : 50px;
$user-loading-size : 360px;

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;

    &:nth-child(1) {
      animation-delay: 0s;
      top: 37px;
      left: 66px;
    }

    &:nth-child(2) {
      animation-delay: -0.1s;
      top: 22px;
      left: 62px;
    }

    &:nth-child(3) {
      animation-delay: -0.2s;
      top: 11px;
      left: 52px;
    }

    &:nth-child(4) {
      animation-delay: -0.3s;
      top: 7px;
      left: 37px;
    }

    &:nth-child(5) {
      animation-delay: -0.4s;
      top: 11px;
      left: 22px;
    }

    &:nth-child(6) {
      animation-delay: -0.5s;
      top: 22px;
      left: 11px;
    }

    &:nth-child(7) {
      animation-delay: -0.6s;
      top: 37px;
      left: 7px;
    }

    &:nth-child(8) {
      animation-delay: -0.7s;
      top: 52px;
      left: 11px;
    }

    &:nth-child(9) {
      animation-delay: -0.8s;
      top: 62px;
      left: 22px;
    }

    &:nth-child(10) {
      animation-delay: -0.9s;
      top: 66px;
      left: 37px;
    }

    &:nth-child(11) {
      animation-delay: -1s;
      top: 62px;
      left: 52px;
    }

    &:nth-child(12) {
      animation-delay: -1.1s;
      top: 52px;
      left: 62px;
    }
  }
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: $grid-size;
  height: $grid-size;

  div {
    position: absolute;
    width: calc($grid-size / 6);
    height: calc($grid-size / 6);
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;

    &:nth-child(1) {
      top: calc($grid-size / 10);
      left: calc($grid-size / 10);
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: calc($grid-size / 10);
      left: calc($grid-size / 2.5);
      animation-delay: -0.4s;
    }

    &:nth-child(3) {
      top: calc($grid-size / 10);
      left: calc($grid-size / 1.428);
      animation-delay: -0.8s;
    }

    &:nth-child(4) {
      top: calc($grid-size / 2.5);
      left: calc($grid-size / 10);
      animation-delay: -0.4s;
    }

    &:nth-child(5) {
      top: calc($grid-size / 2.5);
      left: calc($grid-size / 2.5);
      animation-delay: -0.8s;
    }

    &:nth-child(6) {
      top: calc($grid-size / 2.5);
      left: calc($grid-size / 1.428);
      animation-delay: -1.2s;
    }

    &:nth-child(7) {
      top: calc($grid-size / 1.428);
      left: calc($grid-size / 10);
      animation-delay: -0.8s;
    }

    &:nth-child(8) {
      top: calc($grid-size / 1.428);
      left: calc($grid-size / 2.5);
      animation-delay: -1.2s;
    }

    &:nth-child(9) {
      top: calc($grid-size / 1.428);
      left: calc($grid-size / 1.428);
      animation-delay: -1.6s;
    }
  }
}

.lds-dual-ring {
  display: inline-block;
  width: $dual-ring-size;
  height: $dual-ring-size;

  &:after {
    content: " ";
    display: block;
    width: calc($dual-ring-size / 1.25);
    height: calc($dual-ring-size / 1.25);
    margin: calc($dual-ring-size / 10);
    border-radius: 50%;
    border: calc($dual-ring-size / 13.333) solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

.user-loading {
  width: $user-loading-size;
  height: calc($user-loading-size / 3.6);
  display: block;
  background-image: 
    linear-gradient(100deg, transparent, rgba(38, 50, 56, 0.5) 50%, transparent 80%), 
    radial-gradient(circle calc($user-loading-size / 7.2) at calc($user-loading-size / 7.2) calc($user-loading-size / 7.2), #FFF 99%, transparent 0), 
    linear-gradient(#FFF calc($user-loading-size / 18), transparent 0), 
    linear-gradient(#FFF calc($user-loading-size / 18), transparent 0), 
    linear-gradient(#FFF calc($user-loading-size / 18), transparent 0);
  background-repeat: no-repeat;
  background-size: 
    calc($user-loading-size / 4.8) calc($user-loading-size / 3.6), 
    calc($user-loading-size / 3.6) calc($user-loading-size / 3.6), 
    calc($user-loading-size / 2.88) calc($user-loading-size / 18), 
    calc($user-loading-size / 1.38) calc($user-loading-size / 18), 
    calc($user-loading-size / 1.38) calc($user-loading-size / 18);
  background-position: 
    -50% 0, 0 0, 
    calc($user-loading-size / 3) 0, 
    calc($user-loading-size / 3) calc($user-loading-size / 9), 
    calc($user-loading-size / 3) calc($user-loading-size / 4.5), 
    calc($user-loading-size / 3) calc($user-loading-size / 3);
  box-sizing: border-box;
  transform-origin: 0 0;
  transform: scale(0.5);
  opacity: .7;
  animation: animloader 1s linear infinite;
}



@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-default {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 0 0, 120px 0, 120px 40px, 120px 80px, 120px 120px;
  }

  100% {
    background-position: 100% 0, 0 0, 120px 0, 120px 40px, 120px 80px, 120px 120px;
  }
}