$progress-bar-height:17px;

.chart-card{
  h5{
    font-size: 32px;
  }

  .data{
    margin-top: -70px;

    .time, .objective{
      font-size: 32px;
      font-weight: 700;

      small{
        font-size: 26px;
      }
    }
  }
}

.bar-line{
  width: 40%;
  height: 6px;
  margin-right: 1rem;
}

.acd-progress-bar{
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  .progress-container{
    background-color: #0C292E;
    height: $progress-bar-height;
    border-radius: 50px;
    width: 100%;
    display: flex;
    gap: 0;
    overflow: hidden;

    .bar-value{
      height: 100%;
      transition: all .3s;
    }
  }

  .bar-line{
    position: absolute;
    height: calc($progress-bar-height * 1.5);
    width: 2px;
    background-color: #fff;
    top: calc($progress-bar-height / 2);
    transform: translateY(-50%);
  }

  .labels{
    position: relative;
    margin-top: .8rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;

    span{
      position: absolute;
      top: 0;

      &.min{
        left: 0;
      }

      &.value{
        transform: translateX(-50%);
        transition: all .3s;
      }

      &.max{
        right: 0;
      }
    }
  }
}