.performance-page{
  .title{
    font-size: 24px;
  }

  .title2{
    font-size: 18px;
  }

  .line{
    background-color: #0C292E;
    height: 2px;
  }
}