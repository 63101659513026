@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
  font-family: 'Conthrax SemiBold';
  src: url(../fonts/conthrax-sb.otf) format('truetype');
}

body{
  font-family: "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.screen-container{
  position: relative;
  background-color: #0C292E;
  color: white;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

main{
  height: 100%;
}

h1{
  color: #DC8B26;
  font-family: 'Conthrax SemiBold', sans-serif;
  font-size: 24px !important;
  margin-bottom: 0 !important;
}

.theme-card{
  background-color: #10353E;
  padding: 27px 2rem;
}

.btn-fullscreen{
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 4;
}

.blur-page{
  filter: blur(6px);
}

.h-100{
  height: 100%;
}

.flex-gap{
  gap: 12px;
}

.text-lighter{
  font-weight: lighter;
}

.swiper-agent{
  // height: 50vh;
  width: 100%;
}