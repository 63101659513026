@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 5px;
  --c: darkred;
  --w: 60px;

  width: var(--w);
  height: var(--w);
  background-color: #0C292E;
  border-radius: 50%;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
  }

  &:before {
    inset: 0;
    background:
      radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
      conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  }

  &:after {
    inset: calc(50% - var(--b)/2);
    background: var(--c);
    transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
  }

  img {
    width: calc(var(--w) - (var(--b) + 13px));
    height: calc(var(--w) - (var(--b) + 13px));
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
}

.animate {
  animation: p 1s .5s both;
}

.no-round {
  &:before {
    background-size: 0 0, auto;
  }

  &:after {
    content: none;
  }
}

@keyframes p {
  from {
    --p: 0
  }
}

.agent{
  &.active{
    .img-profile{
      &.status{
        &::after{
          background-color: #27E066;
        }
      }
    }
  }

  &.best-agent{
    .img-profile{
      width: 168px;
      height: 168px;
      flex: 0 0 168px;

      img{
        border: 6px solid #FFC738;
      }
    }
  }

  .img-profile{
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    position: relative;

    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      border: 2px solid #8EAEB6;
    }

    &.status{
      &::after{
        content: "";
        background-color: #F72081;
        width: 21%;
        height: 21%;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: 4%;
        right: 4%;
        z-index: 10;
        border: 1px solid #8EAEB6;
      }
    }

    &.ribbon, &.best{
      &::before{
        content: "";
        background-color: #10353E;
        background-size: 82% 82%;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        border-radius: 50%;
        z-index: 10;
      }
    }

    &.ribbon{
      &::before{
        background-image: url(../images/icons/ribbon.png);
        bottom: 0%;
        left: 0%;
        width: 40%;
        height: 40%;
      }
    }

    &.best{
      &::before{
        background-image: url(../images/icons/trophy.png);
        bottom: 0%;
        right: 3%;
        width: 27%;
        height: 27%;
      }
    }
  }
}