.alert-popup {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  &.alert-danger {
    .alert-title {
      background-color: #F53030;
    }
  }

  &.alert-success {
    background-color: #037D2A;
  }

  .alert-title {
    .alert-icon {
      width: 96px;
      height: 96px;
      object-fit: contain;
      object-position: bottom;
    }

    .title {
      font-size: 64px;
      font-weight: bold;
      text-align: right;
      display: block;
      text-transform: uppercase;
    }
  }

  .alert-content {
    background-color: #0C292E;
  }
}