.title{
  font-size: 32px;
  font-weight: 700;
}

.title2{
  font-size: 24px;
  font-weight: 700;
}

.line{
  background-color: #0C292E;
  height: 2px;
}